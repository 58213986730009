<template>
    <div class="containerss" @mousewheel="mousewheel" v-show="show">
        <div class="cards">
            <div class="title">
                <span>留言板</span>
                <!-- <span >x</span> -->
                <img class="titleimg" @click="onPopup" src="../assets/index/close.png" alt="" srcset="">
            </div>
            <div class="card">
          <input v-model.trim="data.info" class="input lyinput" type="text" placeholder="写下你的留言">
          <div class="name">
              <img class="nameicon" src="../assets/index/user.png" alt="" srcset="">
               <input v-model.trim="data.name" class="input xminput " type="text" placeholder="姓名">
          </div>
          <div class="name">
              <img class="nameicon" src="../assets/index/dhh.png" alt="" srcset="">
               <input  v-model.trim="data.phone" class="input xminput " type="text" placeholder="手机">
          </div>
          <div class="name">
              <img class="nameicon" src="../assets/index/dz.png" alt="" srcset="">
               <input v-model.trim="data.add"  class="input xminput " type="text" placeholder="地址">
          </div>
          <div class="name">
              <img class="nameicon" src="../assets/index/yx.png" alt="" srcset="">
               <input v-model.trim="data.mail"  class="input xminput " type="text" placeholder="邮箱">
          </div>
          <my-button class="submit" @click="submit">提交</my-button>
      </div>
        </div>
      
    </div>
</template>

<script setup>
import {reactive} from 'vue'
import {message} from '../api/market'
const data=reactive({
    info:'',
    mail:'',
    name:'',
    add:'',
    phone:''
})
   defineProps({
		show: {
			type: Boolean,
			required: false,
			default: false
		}
	})
    const emit = defineEmits(['close'])
    const onPopup = (e) => {
        emit('close', false)
}
const submit=async()=>{
    var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
   if(!data.info) return  window.$message.error('请填写留言')
    if(!data.phone||!reg_tel.test(data.phone)) return  window.$message.error('请填写正确电话')
    const res= await message({
        message:data.info,
        mobile:data.phone,
        name:data.name,
        email:data.mail,
        address:data.add
    },'post')
    if(res.errno===0){
         window.$message.success('留言成功')
          emit('close', false)
          data.info=''
          data.phone=''
          data.name=''
          data.mail=''
          data.add=''
    }
   
   

}
 const mousewheel=(e)=>{
  e.preventDefault();
}

</script>

<style lang="scss" scoped>
.containerss{
    width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow:hidden
}
.cards{
     width: 300px;
    height: 420px;
    background: #fff;
    border-radius: 4px;
    padding: 10px;
}
 .title{
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        .titleimg{
            height: 1.2vh;
            width: auto;
        }
    }

.card{
    width: 100%;
    height:300px;
    margin-top: 20px;
   
    .input{
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        outline:none
        
    }
    .xminput{
        border: 0;
    }
    .lyinput{
        width: 100%;
        height: 80px;
        padding-left: 10px;
    }
    .submit{
        width: 80%;
        height: 30px;
         margin: 0 auto;
        margin-top: 20px;
       
    }
    .name{
        width: 100%;
        height: 30px;
        margin-top: 10px;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        .nameicon{
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
    }
}

</style>