import {
    create,
    NButton,
    NConfigProvider,
    NMessageProvider,
    NGrid,
    NGi,
    NGridItem,
    NSelect,
    NDrawer,
    NDrawerContent,
    NInput,
    NLayout,
    NLayoutHeader,
    NLayoutSider,
    NLayoutContent,
    NSpace,
    NImage,
    NPopover,
    NSwitch,
    NInputNumber,
    NTable,
    NTh,
    NTd,
    NThead, NTbody,
    NPagination,
    NModal,
    NCard,
    NAvatar,
    NEmpty,
    NCarousel,
    NCarouselItem,
    NScrollbar,
    NLayoutFooter,
    NDataTable
} from 'naive-ui'

export default create({
    components: [NButton, NConfigProvider, NMessageProvider, NGrid,
        NGi,
        NGridItem,
        NSelect,
        NDrawer,
        NDrawerContent,
        NInput,
        NLayout,
        NLayoutHeader,
        NLayoutSider,
        NLayoutContent,
        NSpace,
        NImage,
        NPopover,
        NSwitch,
        NInputNumber,
        NTable,
        NTh,
        NTd,
        NThead,
        NTbody,
        NPagination,
        NModal,
        NCard,
        NAvatar,
        NEmpty,
        NCarousel,
        NCarouselItem,
       NLayoutFooter,
        NScrollbar,
        NDataTable
    ]
})