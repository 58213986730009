import request from '@/api/index.js'

export function type_list(data = {},methoods='GET') {
	return request({
		url: '/type_list/',
		data: data,
        method:methoods
	})
}
export function recommended(data = {},methoods='GET') {
	return request({
		url: '/recommended/',
		params: data,
        method:methoods
	})
}
export function message(data = {},methoods='GET') {
	return request({
		url: '/message/',
		data: data,
        method:methoods
	})
}
