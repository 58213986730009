<template>
  <div class="container">
    <n-layout-header class="header" style="border-bottom: 0">
      <div class="headers">
        <div class="headerlog">
          <div class="title">众他科技首页</div>
          <div class="headerlright">
            <!-- <div class="loginbtn">
                            <div @click="jump('/login')">登录</div>
                            <div class="registerbtn">免费注册</div>
                        </div> -->
          </div>
        </div>
        <n-grid
          item-responsive
          style="width: 100%; height: 55%"
          :style="back(current)"
        >
          <!--  -->
          <n-gi span="0 400:0 800:2 1200:3"> </n-gi>
          <n-gi span="24 400:24 800:20 1200:18">
            <div class="headernav">
              <div class="navleft">
                <img class="logimg" src="../assets/logo.png" alt="" srcset="" />
                <span class="logname">众他科技</span>
              </div>
              <div class="headernavmain">
                <div
                  class="navitem"
                  :class="{ choose: current == item.id }"
                  @click="choosenav(item)"
                  v-for="item in nav"
                  :key="item.id"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </n-gi>
          <n-gi span="0 400:0 800:2 1200:3"> </n-gi>
        </n-grid>
      </div>
    </n-layout-header>
    <n-layout-content>
      <router-view />
    </n-layout-content>
    <n-layout-footer>
      <div class="footer">
        <div class="footerhead">
          <span>为你提供全面尊贵的服务</span>
          <div class="footrheadbtn" @click="show = true">立即咨询</div>
        </div>
        <div class="footercenter"></div>
        <div class="footermain">
          <n-grid item-responsive>
            <n-gi span="0 400:0 800:0 1200:3"> 9 </n-gi>
            <n-gi span="24 400:24 800:24 1200:18">
              <div class="footesrmains">
                <div class="footercontent">
                  <div class="footercontentleft">
                    <span style="font-size: 18px">重庆众他科技有限公司</span>
                    <span>地址：南岸区响水路11号3楼</span>
                    <span>手机：{{ phone?.value || "" }}</span>
                    <span>邮编：400109</span>
                  </div>
                  <div class="footercontentright">
                    <span style="font-size: 18px">{{
                      phone?.value || ""
                    }}</span>
                    <span>周一至周六（8：00-18：00）</span>
                  </div>
                </div>
              </div>
              <div class="footers">
                <span> 重庆众他科技有限公司 版权所有 ALL RIGHTRESERVED </span>
                <div class="yyzz">
                  <span>营业执照</span>
                  <span>
                    <a
                      href="https://beian.miit.gov.cn/#/Integrated/index"
                      target="_blank"
                      class="link"
                      >渝ICP备2023000915号</a
                    >
                  </span>
                </div>
              </div>
            </n-gi>
            <n-gi span="0 400:0 800:0 1200:3"> 12 </n-gi>
          </n-grid>
        </div>
      </div>
    </n-layout-footer>
    <div class="float">
      <!-- <img @click="show=true" class="floatone" src="../assets/index/zx.png" alt="" srcset=""> -->
      <div class="floatone" @click="show = true">
        <img
          class="floatoneimg"
          src="../assets/index/kef1.png"
          alt=""
          srcset=""
        />
        <span>客服</span>
      </div>
      <n-popover trigger="hover" placement="left">
        <template #trigger>
          <!-- <img class="floatone" src="../assets/index/dh.png" alt="" srcset=""> -->
          <div class="floatone floatone1">
            <img
              class="floatoneimg"
              src="../assets/index/dh1.png"
              alt=""
              srcset=""
            />
            <span>联系电话</span>
          </div>
        </template>
        <span>{{ phone?.value || "" }}</span>
      </n-popover>
    </div>
    <my-pop :show="show" @close="close"></my-pop>
  </div>
</template>

<script setup>
import { jump } from "../until/fun";
import router from "@/router/index";
import { computed, ref, reactive, watch } from "vue";
import store from "@/store";
const phone = ref();
phone.value = computed(() => store.state.Globals.phone);

const current = ref(0);
const show = ref(false);
const back = computed(() => (i) => {
  let arr = {
    0: "background-color:#e2e7ed",
    1: "background-color:#9DC6EE",
    2: "background-color:#e2e7ed",
    3: "background-color:linear-gradient(183deg, #D1D5D9 0%, #E0E3E8 100%)",
    4: "background-color:#fff",
    5: "background-color:rgb(171,174,181,.7) ",
    6: "background-color:rgb(216,189,183,.5)",
  };
  return arr[i];
});

const close = (e) => {
  show.value = e;
};
const nav = computed(() => {
  return [
    {
      id: 0,
      name: "首页",
      url: "/",
    },
    {
      id: 1,
      name: "关于我们",
      url: "/about",
    },
    {
      id: 2,
      name: "应用市场",
      url: "/market",
    },

    {
      id: 4,
      name: "新闻中心",
      url: "/new",
    },
    {
      id: 5,
      name: "众他风采",
      url: "/demeanor",
    },
    {
      id: 6,
      name: "荣誉资质",
      url: "/honor",
    },
    {
      id: 3,
      name: "解决方案",
      url: "",
    },
  ];
});
const routers = watch(
  () => router.currentRoute.value.path,
  (valValue) => {
    let obj = {
      "/": 0,
      "/about": 1,
      "/market": 2,
      "/fenl": 2,
      "/marketdetail": 2,
      "/new": 4,
      "/demeanor": 5,
      "/honor": 6,
    };
    current.value = obj[valValue];
  },
  {
    deep: true,
    immediate: true,
  }
);
const choosenav = (item) => {
  current.value = item.id;
  jump(item.url);
};
</script>

<style lang="scss" scoped>
.link {
  text-decoration: none;
  color: #fff;
}
.link:hover {
  color: #ff7701;
}
.float {
  width: 80px;
  height: 200px;
  position: fixed;
  top: 40%;
  right: 0;

  .floatone {
    width: 100%;
    height: 80px;
    background: #f85900;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin-bottom: 10px;
    cursor: pointer;

    .floatoneimg {
      width: 40px;
      height: 40px;
    }
  }
  .floatone1 {
    background-color: #fc7c32;
  }
}

.container {
  width: 100vw;
  height: 100vh;

  .header {
    width: 100%;
    background: #fffafa;
    height: 13vh;
    border-bottom: 1px solid gray;
    display: flex;

    .headers {
      width: 100%;
      height: 13vh;

      .headerleft {
        width: 100%;
        height: 45%;
        background-color: #3d485d;
      }

      .headerlog {
        width: 100%;
        height: 45%;
        background-color: #3d485d;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .headerlright {
          width: 200px;
          height: 45%;
          background-color: #3d485d;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .loginbtn {
            width: 90%;
            height: 60%;
            border-left: 1px solid #646f84;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 11%;
            color: #fff;
          }

          .registerbtn {
            padding: 2px 7px;
            background: linear-gradient(180deg, #f89f00 0%, #f85900 100%);
          }
        }

        .title {
          color: #fff;
          font-size: 16px;
        }
      }

      .headernav {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .navleft {
          width: 30%;
          height: 100%;
          display: flex;
          align-items: center;

          .logimg {
            width: 50px;
            height: 50px;
          }

          .logname {
            font-size: 20px;
            font-weight: 500;
            color: #f89f00;
          }
        }

        .headernavmain {
          width: 70%;
          height: 100%;
          display: flex;

          .choose {
            color: #f85900 !important;
            background: rgba(255, 185, 60, 0.1);
          }

          .navitem {
            width: 14%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            color: #333333;
            cursor: pointer;
          }
        }
      }
    }
  }

  .contend {
    width: 100%;
    //    height: 200px;
  }

  .footer {
    width: 100%;
    background: red;

    .footerhead {
      width: 100%;
      height: 18vh;
      background-image: url(../assets/index/footerbj.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 33px;

      .footrheadbtn {
        width: 150px;
        height: 40px;
        border: 1px solid #fff;
        border-radius: 4px;
        text-align: center;
        line-height: 40px;
        font-size: 12px;
        cursor: pointer;
        margin-top: 2vh;
      }
    }

    .footercenter {
      width: 100%;
      height: 7vh;
      background-color: #000;
      border-bottom: 1px solid #666;
    }

    .footermain {
      width: 100%;
      height: 40vh;
      background-color: #000;

      .footers {
        width: 100%;
        // height: 10vh;
        // background: blue;
        margin-top: 4vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 14px;

        .yyzz {
          width: 100%;
          height: 6vh;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          color: #555;
          margin-top: 4vh;
        }
      }

      .footesrmains {
        width: 100%;
        // height: 30vh;
        display: flex;
        justify-content: center;

        .footercontent {
          width: 90%;
          height: 20vh;
          border-bottom: 1px solid #666;
          display: flex;
          align-items: center;

          .footercontentleft {
            width: 50%;
            height: 12vh;
            border-right: 1px solid #666;
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: #fff;
            font-size: 16px;
          }

          .footercontentright {
            width: 50%;
            height: 12vh;
            display: flex;
            flex-direction: column;
            color: #fff;
            font-size: 12px;
            padding-left: 2vh;
          }
        }
      }
    }
  }
}
</style>
