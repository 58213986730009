import router from "@/router";
export function Debounce(fn, t) {
	let delay = t || 1000;
	let timer;
	return function() {
		let args = arguments;
		if (timer) {
			clearTimeout(timer);
		}
		timer = setTimeout(() => {
			timer = null;
			// @ts-ignore
			fn.apply(this, args);
		}, delay);
	};
}
export function jump(url){
    let a = ['[object String]', '[object Object]']
    if (a.includes(Object.prototype.toString.call(url))) {
        router.push(url)
    } else {
        throw new Error(`${url}应是字符串或对象！`)
    }
}