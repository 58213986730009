export default {
    namespaced: true,
    state: {
        // 首页展开记录
        phone: 12
    },
    mutations: {
        onPhone(state, val) {
            state.phone = val
        }
    },
}