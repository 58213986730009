<template>
    <div class="btn" @click="onClick">
        <slot></slot>
    </div>
</template>

<script setup>
    import {
        Debounce
    } from '@/until/fun.js'
    const emit = defineEmits(['click'])
    const onClick = Debounce(() => {
        emit('click')
    })
</script>

<style lang="scss" scoped>
    .btn {
        width: 630rpx;
        height: 80rpx;
        // background: linear-gradient(270deg, #006DFF 0%, #00A2FF 100%);
        background: #F85900;
        border-radius: 20rpx;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 28rpx;
        color: #fff;
        cursor: pointer;
        border-radius: 4px;
        // margin: 0 auto;
    }
</style>