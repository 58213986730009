import { createApp } from 'vue'
import router from './router'
import naive from '@/naive/index'
import App from './App.vue'
import plugin from './until/plugin'
import store from './store/index'
import '@/until/flexible'
let app = createApp(App)
app.use(router)
app.use(naive)
app.use(plugin)
app.use(store)
app.mount('#app')
