import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/Layout/index'
const routes = [
	{
		path: '/login',
		name: 'logins',
		component: () => import('@/views/login/login'),
	},
    {
		path: '/',
		component: Layout,
		hidden: false,
		children: [{
			path: '/',
			component: () => import('@/views/index/index.vue')
            
		},
        {
			path: '/market',
			component: () => import('@/views/market/index.vue')
		},
        {
			path: '/new',
			component: () => import('@/views/new/index.vue')
		},
        {
			path: '/honor',
			component: () => import('@/views/honor/index.vue')
		},
        {
			path: '/about',
			component: () => import('@/views/about/index.vue')
		},
        {
			path: '/demeanor',
			component: () => import('@/views/demeanor/index.vue')
		},
        {
			path: '/newdetail',
			component: () => import('@/views/new/newdetail.vue')
		},
        {
			path: '/marketdetail',
			component: () => import('@/views/market/marketdetail.vue')
		},
        {
			path: '/fenl',
			component: () => import('@/views/market/fenl.vue')
		}
			
		]
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes,
})



export default router
