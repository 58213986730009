import {createStore} from 'vuex'
import createPersistedstate from 'vuex-persistedstate'
import Globals from "./module/Globals"

export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        Globals
    },
    plugins: [
        createPersistedstate({
            key: 'name',
            storage: window.sessionStorage,
            paths: [ 'Globals']
        })
    ]
})