<template>
    <div>

    </div>
</template>


<script >
    import {
        defineComponent
    } from "vue";
    import {
        useMessage
    } from 'naive-ui'
    export default defineComponent(({
        setup() {
            let message = useMessage()
            window.$message = message
        }
    }))
</script>

<style lang="scss" scoped>

</style>