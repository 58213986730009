import axios from 'axios'
//import {url} from '../config'

const request = axios.create({
    baseURL: '',
    timeout: 9000
})
//请求拦截
request.interceptors.request.use(
    config => {
        config.baseURL = 'http://publicapi.guanxikeji.com/v2/'
        // config.method = 'GET'
        let token = ''
        if (token) {
            config.headers['token'] = token
        }
        return config
    },
    err => {
        Promise.reject(err)
    }
)
//响应拦截
request.interceptors.response.use(
    config => {
        
        let {errno, data, errmsg} = config.data
        if (errno === 0||!errno) {
            return Promise.resolve(config.data)
        }
        if (errno != 0) {
            return Promise.resolve(config.data)
        }
        // if (code === 401) {
        //     fun.clearData()
        // }
    },
    err => {
        if (err.response.status === 401) {
            // fun.clearData()
        } else {
            Promise.reject({code: 500, api: err.config.url})
            // let str = err.response.data.msg || "错误请重试！"
            // window.$message.error(str)
        }

    }
)
export default request
